<template>
  <div>
    <div class="dashboard-container">
      <Sidebar v-bind="{ tabs }" />
      <div>
        <!-- <Toolbar /> -->
        <v-content>
          <router-view></router-view>
        </v-content>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "./sidebar";
import Toolbar from "./toolbar";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Sidebar,
    Toolbar,
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: "Workspace",
          icon: "/img/dashboard/client-icons/workspace.svg",
          active: true,
          route_name: "client_overview",
        },
        {
          id: 2,
          name: "Roadmap",
          icon: "/img/dashboard/client-icons/roadmap.svg",
          active: false,
          route_name: "client_roadmap",
        },
        {
          id: 3,
          name: "Analytics",
          icon: "/img/dashboard/client-icons/analytics.svg",
          active: false,
          route_name: "project_analytics",
        },
        {
          id: 4,
          name: "Payments",
          icon: "/img/dashboard/client-icons/payments.svg",
          active: false,
          route_name: "client_payments",
        },
        {
          id: 5,
          name: "Collaborators",
          icon: "/img/dashboard/client-icons/collaborators.svg",
          active: false,
          route_name: "product_collaborators",
        },
        {
          id: 6,
          name: "Documents",
          icon: "/img/dashboard/client-icons/documents.svg",
          active: false,
          route_name: "document_tab",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("userManagement", ["currentUser"]),
  },
};
</script>
<style scoped>
</style>