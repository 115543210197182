var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "nav-wrapper" }, [
        _c("div", { staticClass: "page-title" }, [_vm._v("Workspace")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }